require('./bootstrap');
require('jquery')
require('jquery-match-height');


$(document).ready(function() {

    $(function() {
        $('.card-body').matchHeight();
    });

    $('a[href^="#"]').on('click', function(e) {
        e.preventDefault();

        var target = this.hash;
        var $target = $(target);

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);

    });

});
